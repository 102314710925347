import logo from './logo.svg';
import './App.css';
import React, { useState, useCallback, useEffect } from 'react';

function App() {
  var data
  React.useEffect(() => {
    for(var i = 1; i < 3;i++){
      for(var t = 1; t < 4;t++){
          for(var k = 1;k < 4;k++){
              (function(a,b,c){
                  document.getElementById(`input${a}-${b}-${c}`).addEventListener("input",function(){
                      for(var j = 1;j < 4;j++){
                          data += document.getElementById(`input${a}-${b}-${j}`).value -0
                      }
                      document.getElementById(`output${a}-${b}`).value = data
                      data = 0
                  })
              })(i,t,k)
          }
      }
  }
  })
  return (
    <div>
      <div>
            <label style={{display:"flex", justifyContent:"space-around",width:"500px"}}>
                <p></p>
                <p>set1</p>
                <p>set2</p>
                <p>set3</p>
            </label>
            <label style={{display:"flex"}}>
                <label>
                    <label style={{display:"flex"}}>
                        <p style={{margin:"0"}}>player1</p>
                        <input type="number" id="input1-1-1"></input>
                        <input type="number" id="input1-1-2"></input>
                        <input type="number" id="input1-1-3"></input>
                        <input id="output1-1"></input>
                    </label>
                    <label style={{display:"flex"}}>
                        <p style={{margin:"0"}}>player2</p>
                        <input type="number" id="input1-2-1"></input>
                        <input type="number" id="input1-2-2"></input>
                        <input type="number" id="input1-2-3"></input>
                        <input id="output1-2"></input>
                    </label>
                </label>
                <div>&nbsp;</div>
                <label>
                    <label style={{display:"flex"}}>
                        <input type="number" id="input1-3-1"></input>
                        <input type="number" id="input1-3-2"></input>
                        <input type="number" id="input1-3-3"></input>
                        <input id="output1-3"></input>
                    </label>
                    <label style={{display:"flex"}}>
                        <input type="number" id="input2-1-1"></input>
                        <input type="number" id="input2-1-2"></input>
                        <input type="number" id="input2-1-3"></input>
                        <input id="output2-1"></input>
                    </label>
                </label>
                <div>&nbsp;</div>
                <label>
                    <label style={{display:"flex"}}>
                        <input type="number" id="input2-2-1"></input>
                        <input type="number" id="input2-2-2"></input>
                        <input type="number" id="input2-2-3"></input>
                        <input id="output2-2"></input>
                    </label>
                    <label style={{display:"flex"}}>
                        <input type="number" id="input2-3-1"></input>
                        <input type="number" id="input2-3-2"></input>
                        <input type="number" id="input2-3-3"></input>
                        <input id="output2-3"></input>
                    </label>
                </label>
            </label>
        </div>
    </div>
  );
}

export default App;
